import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Footer from '@unicat/Footer';
import Terms from '@unicat/Terms';
import Language from '@unicat/i18n/LanguageContext';
import { config } from '@config';
import { SEO, Experimental, MultiLanguage, OpenGraph, PreConnect, PreLoad, TwitterCard } from '@unicat/Seo';

export const query = graphql`
  query TermsPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        author
      }
    }
  }
`;

function TermsPage({ data, pageContext, location }) {
  const title = pageContext.pageName;
  const { description } = data.site.meta;
  const language = useContext(Language);
  const { lang } = language ?? { lang: config.DefaultLanguage };

  return (
    <>
      <>
        <SEO title={title} description={description} lang={lang} />
        <MultiLanguage lang={lang} location={location} />
        <TwitterCard title={title} />
        <OpenGraph title={title} />
        <PreLoad />
        <PreConnect />
        <Experimental />
      </>
      <div className={'default-container'}>
        <Terms />
        <Footer />
      </div>
    </>
  );
}

TermsPage.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    site: PropTypes.shape({
      meta: PropTypes.shape({
        author: PropTypes.string,
        description: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
  }),
  location: PropTypes.any,
};

export default TermsPage;
