import React from 'react';
import t from '@unicat/i18n/Translate';
import { useStyles } from './styles';
import { anchors } from '@config/headerRoutes';

const Terms = () => {
  const classes = useStyles();

  return (
    <>
      <section id={anchors.HomeTop} className={classes.wrapper}>
        <h1>{t('Terms And Conditions')}</h1>
        <div className={classes.content}>
          <div className={classes.text}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed neque ut augue convallis dignissim.
              Aliquam dapibus condimentum molestie. Phasellus a commodo ipsum, et aliquet neque. Nunc vestibulum maximus
              tortor, eget pellentesque dolor ullamcorper a. Quisque est ligula, ullamcorper nec massa sit amet, porta
              lacinia odio. Donec lobortis ullamcorper eros sed dapibus. Aliquam nulla leo, venenatis eu pulvinar sit
              amet, porttitor quis diam. Integer mollis commodo dolor, ac dignissim tellus gravida ut. Sed lobortis
              lacus rhoncus tortor condimentum lacinia. Aenean non lacus mattis, posuere enim ut, ultrices justo.
            </p>
            <p>
              Proin vulputate arcu sed est semper, non varius diam posuere. Quisque mattis velit id eros venenatis
              blandit. Etiam mollis et est ut vulputate. Phasellus pharetra rutrum mauris vitae rhoncus. Fusce aliquet
              ligula in dictum posuere. Sed id fermentum arcu. Etiam vitae ipsum tincidunt neque porttitor cursus.
            </p>
            <p>
              Ut felis mi, finibus ac cursus non, sollicitudin in mauris. Fusce maximus lorem sit amet est efficitur,
              eget rutrum elit laoreet. Donec nec dictum ligula. Quisque sit amet dui luctus, tempus augue et, malesuada
              sem. Aliquam ac accumsan nisi, ac molestie risus. Aenean nec leo aliquet, convallis nisl placerat,
              scelerisque tellus. Sed molestie ante eget ultricies elementum. Donec iaculis elit tellus, vel volutpat
              dui molestie ut. Proin non odio turpis. Suspendisse mollis eleifend augue, et dignissim turpis vulputate
              eu. Nam feugiat, tortor quis gravida faucibus, odio justo venenatis felis, nec pellentesque sapien nulla
              vitae libero.
            </p>
            <p>
              Suspendisse potenti. Suspendisse eu lacinia quam. Vivamus ultrices orci et velit consequat feugiat. Morbi
              venenatis imperdiet lacinia. Phasellus tempus hendrerit viverra. Aenean elit tortor, condimentum ac diam
              vitae, interdum auctor justo. Fusce a felis at arcu sodales ultrices at ac nibh. Vivamus sed consequat
              sapien. Curabitur lacus risus, elementum eget convallis in, consequat at augue.
            </p>
            <p>
              Cras eget eros neque. Praesent tincidunt tempor risus sit amet vehicula. Fusce egestas non lectus a
              convallis. Maecenas pharetra urna non velit rhoncus, at faucibus diam pellentesque. Donec varius ligula eu
              dui dignissim tempus a nec enim. Sed tempus nulla ligula, quis dapibus elit faucibus vitae. Ut egestas
              tellus augue, in condimentum leo maximus in. Fusce maximus et leo et pretium.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
